@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#typewritter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.12em; /* Adjust as needed */
  animation: typing 1.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

h1 {
  font-size: xx-large;
}

@media screen and (max-width: 600px) {
  p {
    font-size: 8px;
  }
  button {
    font-size: 8px;
  }
  h1 {
    font-size: medium;
  }
  h2 {
    font-size: xx-small;
  }
  .title {
    font-size: 8px;
  }
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 95%;
  bottom: 0;
  z-index: -1;
  -o-filter: blur(7px);
  filter: blur(7px);
  object-fit: cover;
  transform: scaleX(1.1);
}

#thelanguage {
  position: absolute;
  left: 0;
  right: 0;
  top: 190%;
  bottom: 0;
  background-color: rgb(20, 22, 25);
  width: 100vw;
  height: 60vh;
}

#featuredOn {
  position: absolute;
  left: 0;
  right: 0;
  top: 255%;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  width: 100vw;
  height: 100vh;
}

#raytracer {
  position: absolute;
  left: 0;
  right: 0;
  top: 355%;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
}

#actingjobs {
  position: absolute;
  left: 0;
  right: 0;
  top: 455%;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  width: 100vw;
  height: 60vh;
}

#socials {
  position: absolute;
  left: 0;
  right: 0;
  top: 515%;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 60vh;
}

.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css);

.droid-arabic-kufi {
  font-family: "Droid Arabic Kufi", serif;
}

.smooth {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  font-size: 200px;
}

.deck {
  position: absolute;
  width: 600px;
  height: 400px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 300px;
  height: 85vh;
  max-height: 580px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}
